import { Component, Input, OnInit } from '@angular/core';

import { sortRenewalsByPharmacy } from '../../shared/renewal-utils';
import { Renewal } from '../../shared/renewals.type';
import { RenewalCheckoutForm } from '../../shared/renewal-checkout-form';

@Component({
  selector: 'omg-renewal-checkout-prescriptions',
  templateUrl: './renewal-checkout-prescriptions.component.html',
  styleUrls: ['./renewal-checkout-prescriptions.component.scss'],
})
export class RenewalCheckoutPrescriptionsComponent implements OnInit {
  @Input() checkoutForm: RenewalCheckoutForm;

  cartId: number;
  renewalsbyPharmacy: Renewal[][];

  ngOnInit(): void {
    this.cartId = this.checkoutForm.controls.get('rxCartId').value;
    this.renewalsbyPharmacy = sortRenewalsByPharmacy(
      this.checkoutForm.controls.get('refillRequests').value,
    );
  }
}
