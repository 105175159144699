import { Injectable } from '@angular/core';
import { map, Observable, throwError } from 'rxjs';

import { PatientSelectors } from '@app/core';
import { ApiService } from '@app/core/api/api.service';
import { PatientEntityApiService } from '@app/core/store/shared/entity-api-service';

import {
  RxCartTypes,
  RxVerification,
  RxVerificationDevicesResponse,
  RxVerificationDeviceTypes,
  RxVerifyPayload,
  RxVerifyResponse,
} from './rx-verification.type';
import {
  MarkReadyToSignMutationService,
  MarkRxCartReadyToSignMutationService,
} from './rx-verification.onelife.generated';

const mapToRxCartReadyToSignResult = ({
  loading: _loading,
  data: {
    markRxCartReadyToSign: { success },
  },
}): boolean => success;

const mapToReadyToSignResult = ({
  loading: _loading,
  data: {
    markReadyToSign: { success },
  },
}): boolean => success;

// includes getById(), getAll(), update(), delete(), and save() by default
@Injectable({
  providedIn: 'root',
})
export class RxVerificationApiService extends PatientEntityApiService<RxVerification> {
  readonly options = {
    params: { ignore401: true },
  };

  constructor(
    api: ApiService,
    patientSelectors: PatientSelectors,
    private markRxCartReadyToSignMutationService: MarkRxCartReadyToSignMutationService,
    private markReadyToSignMutationService: MarkReadyToSignMutationService,
  ) {
    super(
      api,
      {
        basePath: '/v2/admin/patients/:patientId/rx_checkout',
        params: null,
      },
      patientSelectors,
    );
  }

  buildRoute(rxCartType: RxCartTypes, subRoute: string): string {
    return `${this.basePath}/${rxCartType}/${subRoute}`;
  }

  getVerificationDevices(
    rxCartType: RxCartTypes,
  ): Observable<RxVerificationDevicesResponse> {
    return this.api.get(
      this.buildRoute(rxCartType, 'verified_devices'),
      null,
      this.options,
    );
  }

  verify(payload: RxVerifyPayload): Observable<RxVerifyResponse> {
    switch (payload.device.type) {
      case RxVerificationDeviceTypes.push:
        return this.verifyWithPush(payload);
      case RxVerificationDeviceTypes.code:
        return this.verifyWithCode(payload);
      default:
        return throwError('Unknown Two Factor Verification Device Type');
    }
  }

  verifyWithPush(payload: RxVerifyPayload): Observable<RxVerifyResponse> {
    const data = {
      password: payload.password,
      rxCartId: payload.cartId,
      deviceId: payload.device.id,
    };

    return this.api.save<RxVerifyResponse>(
      this.buildRoute(payload.cartType, 'initiate_push'),
      data,
      this.options,
      true,
    );
  }

  verifyWithCode(payload: RxVerifyPayload): Observable<RxVerifyResponse> {
    const data = {
      password: payload.password,
      rxCartId: payload.cartId,
      deviceId: payload.device.id,
      code: payload.code,
    };

    return this.api.save<RxVerifyResponse>(
      this.buildRoute(payload.cartType, 'verify_code'),
      data,
      this.options,
      true,
    );
  }

  /**
   * Mark the ready-to-sign as attested by the provider.
   */
  markReadyToSign(rxCartId: number): Observable<boolean> {
    return this.markRxCartReadyToSignMutationService
      .mutate({ rxCartId: `${rxCartId}` })
      .pipe(map(mapToRxCartReadyToSignResult));
  }

  /**
   * Mark Rx Source as ready-to-sign as attested by the provider.
   */
  markAsReadyToSign(rxCartId: number, rxSourceId: number): Observable<boolean> {
    return this.markReadyToSignMutationService
      .mutate({ rxCartId: `${rxCartId}`, rxSourceId: `${rxSourceId}` })
      .pipe(map(mapToReadyToSignResult));
  }
}
