/* eslint-disable */
// @ts-nocheck
/*
====================================================
THIS IS AN AUTOGENERATED FILE
DO NOT EDIT. 
Update with `npm run graphql-codegen`
====================================================
*/
import * as Types from '../../../../graphql/onelife.type';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { Apollo, Query, Mutation, Subscription } from 'apollo-angular';
export type MarkRxCartReadyToSignMutationVariables = Types.Exact<{
  rxCartId: Types.Scalars['ID']['input'];
}>;


export type MarkRxCartReadyToSignMutation = { __typename?: 'Mutation', markRxCartReadyToSign?: { __typename?: 'MarkRxCartReadyToSignPayload', success: boolean } | null };

export type MarkReadyToSignMutationVariables = Types.Exact<{
  rxCartId: Types.Scalars['ID']['input'];
  rxSourceId: Types.Scalars['ID']['input'];
}>;


export type MarkReadyToSignMutation = { __typename?: 'Mutation', markReadyToSign?: { __typename?: 'MarkReadyToSignPayload', success: boolean } | null };

export const MarkRxCartReadyToSignDocument = gql`
    mutation MarkRxCartReadyToSign($rxCartId: ID!) {
  markRxCartReadyToSign(input: {rxCartId: $rxCartId}) {
    success
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MarkRxCartReadyToSignMutationService extends Mutation<MarkRxCartReadyToSignMutation, MarkRxCartReadyToSignMutationVariables> {
    document = MarkRxCartReadyToSignDocument;
    client = 'onelife';
    constructor(apollo: Apollo) {
      super(apollo);
    }
  }
export const MarkReadyToSignDocument = gql`
    mutation MarkReadyToSign($rxCartId: ID!, $rxSourceId: ID!) {
  markReadyToSign(input: {rxCartId: $rxCartId, rxSourceId: $rxSourceId}) {
    success
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MarkReadyToSignMutationService extends Mutation<MarkReadyToSignMutation, MarkReadyToSignMutationVariables> {
    document = MarkReadyToSignDocument;
    client = 'onelife';
    constructor(apollo: Apollo) {
      super(apollo);
    }
  }