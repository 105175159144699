import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { RxChangeRequest } from '@app/features/change-rx/shared/change-rx.type';
import {
  Renewal,
  RenewalCartState,
} from '@app/features/renewals/shared/renewals.type';
import { PendingNewRx } from '@app/modules/rx-cart/shared';

import { RefillText } from '../../shared-rx.type';
import {
  CheckoutConfirmationEvent,
  CheckoutConfirmationType,
} from './checkout-list-item.type';
import { FeatureFlagNames, LaunchDarklyService } from '@app/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'omg-checkout-list-item',
  templateUrl: './checkout-list-item.component.html',
  styleUrls: ['./checkout-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckoutListItemComponent implements OnInit {
  @Input() handwrittenConfirmed: UntypedFormControl;
  @Input() readyToSignConfirmed: UntypedFormControl;

  @Input() messages: { [key: string]: string };
  @Input() medication: PendingNewRx | Renewal | RxChangeRequest;
  @Input() isRenewal = false;

  @Output() checked = new EventEmitter<CheckoutConfirmationEvent>();

  itemId: number;
  refillText: RefillText;

  readyToSignEnabled$: Observable<boolean>;

  constructor(private launchDarklyService: LaunchDarklyService) {
    this.readyToSignEnabled$ = this.launchDarklyService.variation$<boolean>(
      FeatureFlagNames.orderingReadyToSign,
      false,
    );
  }

  ngOnInit(): void {
    this.refillText = this.isRenewal ? 'Total Fills' : 'Refills';
  }

  onReadyToSignConfirmation(value: boolean): void {
    if (this.readyToSignConfirmed.disabled) {
      return;
    }

    this.checked.emit({
      id: this.medication.id,
      type: CheckoutConfirmationType.readyToSign,
      value,
    });
  }

  get showMustHandWrite(): boolean {
    if (this.isRenewal) {
      return (
        this.medication.mustHandwrite &&
        (<Renewal>this.medication).cartState !== RenewalCartState.denied
      );
    }
    return this.medication.mustHandwrite;
  }

  onHandwrittenConfirmation(value: boolean): void {
    this.checked.emit({
      id: this.medication.id,
      type: CheckoutConfirmationType.handwritten,
      value,
    });
  }
}
