<div class="om-list">
  <omg-section-title>Prescriptions</omg-section-title>
  <ng-container *ngFor="let renewals of renewalsbyPharmacy">
    <div
      class="om-item"
      *ngFor="let renewal of renewals"
    >
      <omg-renewal-checkout-list-item
        [cartId]="cartId"
        [renewal]="renewal"
      ></omg-renewal-checkout-list-item>
    </div>
    <div class="om-item pharmacy">
      <omg-checkout-pharmacy-info [patientPharmacy]="renewals[0]"></omg-checkout-pharmacy-info>
    </div>
  </ng-container>
</div>
