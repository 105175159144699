import { Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UntypedFormControl } from '@angular/forms';

import { buildCheckoutMessages } from '@app/modules/rx-cart/shared/rx-cart-utils';

import { CheckoutRenewal } from '../../shared/renewals.type';
import { LaunchDarklyService, FeatureFlagNames } from '@app/core';
import { RxVerificationApiService } from '@app/features/rx-verification/shared/rx-verification-api.service';
import { tap, take } from 'rxjs';

@Component({
  selector: 'omg-renewal-checkout-list-item',
  templateUrl: './renewal-checkout-list-item.component.html',
  styleUrls: ['./renewal-checkout-list-item.component.css'],
})
export class RenewalCheckoutListItemComponent implements OnInit {
  @Input() renewal: CheckoutRenewal;
  @Input() cartId: number;

  readyToSignConfirmed = new UntypedFormControl();
  handwrittenConfirmed = new UntypedFormControl();
  checkoutMessages = buildCheckoutMessages();

  private destroyRef = inject(DestroyRef);

  constructor(
    private rxVerificationApiService: RxVerificationApiService,
    private launchDarklyService: LaunchDarklyService,
  ) {}

  ngOnInit(): void {
    this.launchDarklyService
      .variation$(FeatureFlagNames.orderingReadyToSign, false)
      .pipe(
        tap(enabled => {
          if (enabled) {
            this.readyToSignConfirmed.valueChanges
              .pipe(takeUntilDestroyed(this.destroyRef))
              .subscribe(checked => {
                this.renewal.readyToSignConfirmed = checked;
                if (checked) {
                  this.rxVerificationApiService
                    .markAsReadyToSign(this.cartId, this.renewal.id)
                    .pipe(take(1))
                    .subscribe();
                }
              });
          } else {
            this.readyToSignConfirmed.patchValue(true);
          }
        }),
        take(1),
      )
      .subscribe();

    this.handwrittenConfirmed.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(checked => {
        this.renewal.handwrittenConfirmed = checked;
      });
  }
}
