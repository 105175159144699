<div
  class="omg-dialog-container"
  omgTheme
  *ngIf="changeRx$ | ngrxPush as changeRx"
>
  <div omgDialogTitleDirective>
    <h5 class="renewal-checkout-title">
      {{ changeRxTypes[changeRx.changeRequestType] }} {{ isApproved ? 'Approval' : 'Denial' }}
    </h5>
    <button
      omgButton
      variant="none"
      class="om-icon icon-close clickable"
      (click)="close()"
      data-cy="renewal-checkout-close-btn"
    ></button>
  </div>
  <div omgDialogContentDirective>
    <div
      om-layout="vertical"
      *ngIf="patient$ | ngrxPush as patient"
    >
      <omg-checkout-patient-info
        [patient]="patient"
        [pediatricVitalsEnabled]="pediatricVitalsEnabled$ | ngrxPush"
      ></omg-checkout-patient-info>
    </div>
    <div
      om-layout="vertical"
      *ngIf="changeRx$ | ngrxPush as changeRx"
    >
      <omg-section-title>Prescription</omg-section-title>
      <omg-change-rx-medication-item
        [medication]="changeRx.originalMedicationPrescription"
      ></omg-change-rx-medication-item>
    </div>
    <ng-container *ngIf="!isApproved">
      <div om-layout="vertical">
        <omg-section-title>{{ changeRxTypes[changeRx.changeRequestType] }} Denied</omg-section-title>
        <div class="padding-normal">
          <omg-change-rx-status-approval [denied]="true"></omg-change-rx-status-approval>
          <div class="padding-normal-vertical">
            <strong>Denial Note</strong>
            <div>{{ reasonForDenial.description }}</div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="isApproved">
      <div
        om-layout="vertical"
        *ngIf="changeRx.changeRequestType !== 'P' && changeRx.changeRequestType !== 'U'"
      >
        <omg-section-title>New Prescription</omg-section-title>
        <omg-change-rx-medication-change-status
          [changeRx]="changeRx"
          (checked)="onChecked($event, changeRx.rxChangeRequest?.rxCart.id)"
        ></omg-change-rx-medication-change-status>
      </div>
      <div
        om-layout="vertical"
        *ngIf="changeRx.changeRequestType === 'P'"
      >
        <omg-section-title>Prior Authorization</omg-section-title>
        <omg-change-rx-prior-auth-status [form]="data.form"></omg-change-rx-prior-auth-status>
      </div>
      <div
        om-layout="vertical"
        *ngIf="changeRx.changeRequestType === 'U'"
      >
        <omg-section-title>Provider Authorization</omg-section-title>
        <omg-change-rx-provider-auth-status [form]="data.form"></omg-change-rx-provider-auth-status>
      </div>
    </ng-container>
    <div
      class="om-item"
      *ngIf="isApproved && changeRx.rxChangeRequest"
    >
      <div
        class="banner -warning"
        *ngIf="changeRx.medicationRoute.id !== changeRx.rxChangeRequest.medicationRoute.id"
        data-cy="new-medication-warning"
      >
        <p>By approving, the original medication will be discontinued in the patient's medication list</p>
      </div>
    </div>
    <omg-rx-verification
      [cartStatus]="changeRxCartStatus"
      [cartType]="cartType"
      [cartId]="changeRx.rxChangeRequest?.rxCart.id"
      [cartErrors]="errorState$ | ngrxPush"
      [readyToSignAttested]="readyToSignAttested"
      (completeVerification)="send($event)"
      (cancel)="close()"
    ></omg-rx-verification>
  </div>
</div>
