import {
  Component,
  DestroyRef,
  Inject,
  inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

import { Patient, PatientSelectors } from '@app/core';
import {
  Renewal,
  RenewalCart,
} from '@app/features/renewals/shared/renewals.type';
import { PatientMedicationActions } from '@app/modules/medications/store/patient-medication.actions';
import { CheckoutDialogComponent } from '@app/modules/shared-rx/components/checkout-dialog/checkout-dialog.component';
import { RxCartMeasurement } from '@app/modules/shared-rx/shared-rx.type';
import { DialogRef, OMG_DIALOG_DATA } from '@app/shared/components/dialog';

import { RenewalCheckoutForm } from '../../shared/renewal-checkout-form';
import { RenewalFormService } from '../../shared/renewal-form.service';
import { RenewalActions, RenewalSelectors } from '../../store';
import { defaultCartState } from '../../store/renewals.reducer';
import { RenewalSignatureComponent } from '../renewal-signature/renewal-signature.component';

interface RenewalCheckoutModalData {
  renewals: Renewal[];
  acceptsDigitalCommunications: boolean;
  cartId: number;
}

@Component({
  selector: 'omg-renewal-checkout-dialog',
  templateUrl: './renewal-checkout-dialog.component.html',
  styleUrls: ['./renewal-checkout-dialog.component.scss'],
})
export class RenewalCheckoutDialogComponent implements OnInit {
  patient$: Observable<Patient>;
  cartState$: Observable<RenewalCart>;
  rxCartMeasurements$: Observable<RxCartMeasurement[]>;
  checkoutForm: RenewalCheckoutForm;

  private destroyRef = inject(DestroyRef);

  @ViewChild(RenewalSignatureComponent)
  renewalSignatureComponent: RenewalSignatureComponent;

  constructor(
    public dialogRef: DialogRef<CheckoutDialogComponent>,
    private patientSelectors: PatientSelectors,
    @Inject(OMG_DIALOG_DATA) public data: RenewalCheckoutModalData,
    private renewalFormService: RenewalFormService,
    private renewalSelectors: RenewalSelectors,
    private renewalActions: RenewalActions,
    private patientMedicationActions: PatientMedicationActions,
  ) {}

  ngOnInit(): void {
    this.patient$ = this.patientSelectors.patient;
    this.cartState$ = this.renewalSelectors.cartState;
    this.rxCartMeasurements$ = this.renewalSelectors.rxCartMeasurements;
    this.buildForm();
    this.setListeners();
  }

  close = (): void => {
    this.renewalActions.updateCartState(defaultCartState);
    this.dialogRef.close();
  };

  private buildForm(): void {
    this.checkoutForm = this.renewalFormService.buildCheckoutForm(
      this.data.renewals,
      this.data.acceptsDigitalCommunications,
      this.data.cartId,
    );
  }

  private setListeners(): void {
    this.renewalSelectors.cartCompleteAt
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter(complete => !!complete),
        switchMap(() => this.patientSelectors.patientId),
      )
      .subscribe(patientId => {
        this.patientMedicationActions.loadPatientMedications(patientId);
        this.close();
      });
  }
}
