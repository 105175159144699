<div
  class="omg-dialog-container"
  omgTheme
>
  <div omgDialogTitleDirective>
    <h5 class="renewal-checkout-title">Renewal Checkout</h5>
    <button
      omgButton
      variant="none"
      class="om-icon icon-close clickable"
      (click)="close()"
      data-cy="renewal-checkout-close-btn"
    ></button>
  </div>
  <div omgDialogContentDirective>
    <div
      om-layout="vertical"
      *ngIf="checkoutForm && (patient$ | ngrxPush) as patient"
    >
      <omg-checkout-patient-info
        [patient]="patient"
        [rxCartMeasurements]="rxCartMeasurements$ | ngrxPush"
      ></omg-checkout-patient-info>
      <omg-renewal-checkout-prescriptions [checkoutForm]="checkoutForm"></omg-renewal-checkout-prescriptions>
      <omg-renewal-signature
        [patient]="patient"
        [renewalCheckoutForm]="checkoutForm"
        [cartState]="cartState$ | ngrxPush"
        (cancel)="close()"
      ></omg-renewal-signature>
    </div>
  </div>
</div>
