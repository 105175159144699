<div om-layout="vertical">
  <div class="om-item">
    <omg-change-rx-status-approval></omg-change-rx-status-approval>
  </div>
  <div class="om-item">
    <div><strong>Medication Change</strong></div>
    <omg-checkout-list-item
      [medication]="changeRx.rxChangeRequest"
      [isRenewal]="true"
      [readyToSignConfirmed]="readyToSignConfirmed"
      (checked)="checked.emit($event)"
    ></omg-checkout-list-item>
  </div>
  <omg-signed-by-another
    [isSignedOnBehalfOf]="isSignedOnBehalfOf$ | ngrxPush"
    [prescriberName]="signedOnBehalfOfName"
  ></omg-signed-by-another>
</div>
